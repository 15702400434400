import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ContentService } from 'src/app/content.service';
import { EMPTY } from 'rxjs';
import { switchMap, debounceTime } from 'rxjs/operators';
import { MdcDialog, MdcTabActivatedEvent } from '@angular-mdc/web';
import { DialogSendGavekortComponent } from '../dialogs/dialog-send-gavekort/dialog-send-gavekort.component';
import { AuthService } from 'src/app/auth.service';
import { DialogRefunderingTilbakemeldingComponent } from '../dialogs/dialog-refundering-tilbakemelding/dialog-refundering-tilbakemelding.component';
import { DialogRefunderingComponent } from '../dialogs/dialog-refundering/dialog-refundering.component';
import { printBilletterFraOrdre } from '../../salg/print-stuff.js';


@Component({
  selector: 'app-ordreoppslag',
  templateUrl: './ordreoppslag.component.html',
  styleUrls: ['./ordreoppslag.component.scss']
})
export class OrdreoppslagComponent implements OnInit {

  @Input() forestillingId:number;// eksempel: 782;

  public sokInput = new FormControl('');
  public treff: any = {};
  public ordre;// valgt ordre eller den ene, når man bare fikk ett treff
  public pending = false;
  public activeTab: any;
  public sub;

  constructor(
    private contentService: ContentService,
    private dialog: MdcDialog,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.getOrdre();
  }

  switchTab(event: MdcTabActivatedEvent): void {
    this.activeTab = event.tab.label;
    this.getOrdre();
  }

  getOrdre() {
    delete this.treff;
    delete this.ordre;
    if(this.sub) {
      this.sub.unsubscribe();
      delete this.sub;
    }
    if (this.activeTab === 'Forestilling') {
      this.pending = true;
      this.contentService.fetchContentWithQueryParams(
        '/admin/ordre/query', {forestilling_id: this.forestillingId}
      ).subscribe(treff => {
        this.pending = false;
        this.showResult(treff);
      });
    } else {
      this.sokInput.setValue('');
      this.sub = this.sokInput.valueChanges.pipe(
        debounceTime(500),
        switchMap((value: any) => {
          delete this.treff;
          delete this.ordre;
          if (value !== '') {
            this.pending = true;
            return this.contentService.fetchContentWithQueryParams(
              '/admin/ordre/query', {q: value}
            );
          } else {
            return EMPTY;
          }
        })
      ).subscribe(treff => {
        this.pending = false;
        this.showResult(treff);
      });
    }
  }

  gotoPage(page) {
    this.pending = true;
    if(this.activeTab === 'Forestilling') {
      this.contentService.fetchContentWithQueryParams(
        '/admin/ordre/query', {forestilling_id: this.forestillingId, page: page}
      ).subscribe(treff => {
        this.pending = false;
        this.showResult(treff);
      });
    } else {
      this.contentService.fetchContentWithQueryParams(
        '/admin/ordre/query', {q: this.sokInput.value, page: page}
      ).subscribe( treff => {
        this.pending = false;
        this.showResult(treff);
      });
    }
  }

  ordreErRefunderbarTilKort(ordre) {
    // alt må være betalt på kort
    return this.betaltMedKort(ordre) && this.isNotRefunded(ordre)
  }
  betaltMedKort(ordre) {
    return ordre.ordretype === 'nett' && (ordre.sum_captured_kort === ordre.sum);
  }
  pengerIgjenAaRefundere(ordre) {
    return (ordre.sum_captured_kort - ordre.sum_refunded_kort) > 0;
  }

  showResult(treff) {
    this.treff = treff;
    if (treff.total === 1) {
      this.showDetails(treff.items[0]);
    }
  }

  showDetails(ordre) {
    // TODO flytt transaksjonslogikk hit og generer en forhåndsformatert transaksjonsliste
    this.ordre = ordre;
  }

  showList() {
    delete this.ordre;
  }

  mail(ordre) {
    // send billettmail på nytt via epost-prompt
  }

  sendGavekort(gavekort) {
    this.dialog.open(DialogSendGavekortComponent, {
      data: {
        ordreid: this.ordre.ordreid,
        gavekort: gavekort
      }
    }).afterClosed()
      .subscribe(res => {
        if (res === 'sendt') {
          // Sending ok.
        }
      });
  }

  refundBillett(ordre, billett) {
    // refunder billett og skriv om lokale data, så ui viser riktig status
  }

  refundOrdre(ordre) {
    this.dialog.open(DialogRefunderingComponent).afterClosed().subscribe(res => {
      if (res === 'refund') {
        this.pending = true;
        this.contentService.postContent('/salg/ordre/' + ordre.id + '/refund', { refund_type: 'kort'})
          .subscribe( res => {
              this.pending = false;
              this.ordre = res.original_ordre;
              this.dialog.open(DialogRefunderingTilbakemeldingComponent, {
                data: res
              });
          });
      }
    });
    // refunder som i kassa
  }

  printBilletter() {
    const ER_KOPI = true;
    this.contentService.fetchContent('/salg/forestilling/' + this.forestillingId + '/info')
      .subscribe( forestillinginfo => {
        printBilletterFraOrdre({
          forestillinginfo,
          ordre: this.ordre
        }, ER_KOPI);
      });
  }

  ordreHarGyldigeBilletter() {
    if (this.ordre?.avsluttet && this.ordre.billetter.find( billett => !billett.slettet && !billett.refundert )) {
      return true;// ordren er avsluttet og inneholder minst én ikke-refundert billett
    } else {
      return false;
    }
  }

  isNotRefunded(ordre) {
    if (ordre.billetter.length) {
      return ordre.billetter.find(b => !b.refundert);
    }
    else if (ordre.gavekort.length) {
      return ordre.gavekort.find(b => !b.refundert);
    }
    else {
      return true;
    }
  }

  delete(ordre) {
    // marker ordren som slettet
  }
}
