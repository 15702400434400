<app-spinner *ngIf="pending"></app-spinner>

<header class="ordreoppslag__header">
  <h2 mdcHeadline2>Ordreoppslag</h2>
  <mdc-tab-bar fixed *ngIf="forestillingId" (activated)="switchTab($event)" [activeTabIndex]="1" useAutomaticActivation>
    <mdc-tab-scroller>
      <mdc-tab label="Søk" icon="search"></mdc-tab>
      <mdc-tab label="Forestilling" icon="list"></mdc-tab>
    </mdc-tab-scroller>
  </mdc-tab-bar>
  <ng-container *ngIf="!forestillingId || activeTab === 'Søk'">
    <mdc-text-field outlined label="Søk etter ordre" [formControl]="sokInput" class="search-field"></mdc-text-field>
    <div>Du kan søke etter epostadresse, telefonnummer og ordrenummer</div>
  </ng-container>
</header>

<div class="sadface" *ngIf="treff?.total===0">Ingen treff</div>

<div class="content-list" *ngIf="treff?.total > 1 && !ordre">

  <div class="paginering">
    <ng-container *ngIf="treff.pages > 1">
      <button mdc-button dense class="button-no-bg" [disabled]="!treff.has_prev" (click)="gotoPage(treff.prev_num)">
        <mdc-icon>arrow_back</mdc-icon>Forrige
      </button>
      <div class="page-list">
        {{treff.page}} / {{treff.pages}}
      </div>
      <button mdc-button dense class="button-no-bg" [disabled]="!treff.has_next" (click)="gotoPage(treff.next_num)">
        <span mdcButtonLabel>Neste</span>
        <mdc-icon>arrow_forward</mdc-icon>
      </button>
    </ng-container>
  </div>

  <div class="list-heading">
    <div class="list-item-text--secondary">Id</div>
    <div class="list-item-text">Tid</div>
    <div class="list-item-text forestilling__sted">Epost</div>
    <div class="list-item-text">Navn</div>
    <div class="list-item-text--secondary">Status</div>
    <div class="list-item-text--secondary">Ordretype</div>
    <div class="list-item-text--secondary list-item-text--right">Billetter</div>
  </div>
  <mdc-list>
    <mdc-list-item *ngFor="let ordre of treff.items" (click)="showDetails(ordre)">
      <div class="list-item-text--secondary">{{ ordre.id }}</div>
      <div class="list-item-text">{{ordre.ordredato | date: 'd.M.yyyy kl H:mm'}}</div>
      <div class="list-item-text">
        <span *ngIf="ordre.ordretype === 'nett' || ordre.ordretype === 'kredit'">{{ ordre.kundeinfo?.epost }}</span>
        <span *ngIf="ordre.ordretype === 'luke'">Kassesalg</span>
      </div>
      <div class="list-item-text">{{ordre.kundeinfo?.navn}}</div>
      <div class="list-item-text--secondary">
          <span *ngIf="ordre.avsluttet && !ordre.slettet && isNotRefunded(ordre)">Gyldig</span>
          <span *ngIf="!ordre.avsluttet || (ordre.slettet && isNotRefunded(ordre))">Ikke gyldig</span>
          <span *ngIf="!isNotRefunded(ordre)">Refundert</span>
      </div>
      <div class="list-item-text--secondary">{{ ordre.ordretype }}</div>
      <div class="list-item-text--secondary list-item-text--right">{{ ordre.billetter.length }}</div>
    </mdc-list-item>
  </mdc-list>
</div>


<div *ngIf="ordre">
  <button mdc-button *ngIf="treff.total > 1" class="backlink button-no-bg" (click)="showList()">
    <mdc-icon>arrow_back</mdc-icon> Tilbake til lista
  </button>
  <section class="ordreinfo">
    <div class="rad">
      <div>
        <p class="ordre__status">Status:
          <span *ngIf="ordre.avsluttet && !ordre.slettet && isNotRefunded(ordre)"> Gyldig</span>
          <span *ngIf="!ordre.avsluttet || (ordre.slettet && isNotRefunded(ordre))"> Ikke gyldig</span>
          <span *ngIf="!isNotRefunded(ordre)"> Refundert</span>
        </p>
      </div>
    </div>
    <div class="rad">
      <div class="left">
        <p>Ordrenummer: {{ ordre.ordreid }}</p>
        <p>Ordretidspunkt: {{ ordre.ordredato | date: 'd.M.yyyy kl H:mm' }}</p>
        <p *ngIf="ordre.ordretype === 'luke'">Solgt i kassa</p>
        <p *ngIf="ordre.ordretype === 'nett'">Solgt på nett</p>
      </div>

      <div class="right">
        <p *ngIf="ordre.kundeinfo?.navn">Navn: {{ ordre.kundeinfo?.navn }}</p>
        <p>Epost: {{ ordre.kundeinfo?.epost }}</p>
        <p>Telefon: {{ ordre.kundeinfo?.telefon || ordre.kundeinfo?.tlf }}</p>
      </div>
    </div>
    <div class="rad">
      <div class="left">
        <ng-container *ngIf="ordre.forestilling?.id">
          <p>Forestilling {{ ordre.forestilling.id }}</p>
          <p>{{ ordre.ruteplan.spillested.stedsnavn }}: {{ ordre.ruteplan.spillested.navn }}</p>
          <p><b>{{ ordre.forestilling.film.norsktittel }}</b></p>
          <p>{{ ordre.ruteplan.dato | date: 'd.M.yyyy' }} kl {{ ordre.forestilling.tidspunkt }}</p>
        </ng-container>
      </div>

      <div class="right">
        <h4>Transaksjoner</h4>
        <div *ngIf="ordre.trans.length">
          <ul class="ordre__transaksjoner">
            <li *ngFor="let t of ordre.trans">
              <span *ngIf="t.er_korreksjon">– </span>
              <span [ngClass]="{'transaksjoner__korrigert' : t.er_korrigert, 'transaksjoner__korreksjon' : t.er_korreksjon}">{{ t.betalingstype }}</span>
              <span class="transaksjoner__label" *ngIf="t.er_korrigert"> Korrigert</span>
              <span class="transaksjoner__label" *ngIf="t.er_korreksjon"> Korreksjon</span>
            </li>
          </ul>
        </div>
        <p *ngIf="ordre.kortreservasjoner?.length">Kortreservasjoner: {{ ordre.kortreservasjoner.length }}</p>
        <p *ngIf="ordre.kortkanselleringer?.length">Kortkanselleringer: {{ ordre.kortkanselleringer.length }}</p>

        <p *ngIf="ordre.sum_captured_kort">Betalt gjennom {{ (ordre.kortreservasjoner[0].provider === 'vipps') ? 'Vipps' : 'kortselskap' }}: {{ ordre.sum_captured_kort }} kr</p>
        <p *ngIf="ordre.ordretype === 'nett' && !ordre.sum_captured_kort">Ikke betalt med kort</p>
        <p *ngIf="ordre.sum_refunded_kort">Refundert gjennom {{ (ordre.kortreservasjoner[0].provider === 'vipps') ? 'Vipps' : 'kortselskap' }}: {{ ordre.sum_refunded_kort }} kr</p>

      </div>
    </div>
  </section>

  <section class="billetter">

    <h3 mdcHeadline5 *ngIf="ordre.gavekort?.length">Gavekort</h3>

    <div class="ordre__gavekort" *ngIf="ordre.gavekort?.length">
      <div class="gavekort" *ngFor="let gavekort of ordre.gavekort">
          <p *ngIf="gavekort.mottaker">Mottaker:<br/>{{ gavekort.mottaker?.navn }} {{ gavekort.mottaker?.epost }}</p>
          <p class="gavekort__uuid">{{gavekort.uuid}}</p>
          <div class="gavekort__verdi-gyldighet">
            <p>Originalverdi: kr {{ gavekort.org_verdi }}</p>
            <p>Gjenstående: kr {{ gavekort.verdi }}</p>
            <p>Gyldig til {{ gavekort.gyldig_ts | date: 'd.M.yyyy HH:mm'}}</p>
          </div>
          <div class="gavekort__refundert" *ngIf="gavekort.refundert">
            <p>Refundert</p>
            <p>Refundert beløp: kr {{ gavekort.refundert_belop }}</p>
          </div>
          <button mdc-button outlined class="button-no-bg gavekort__send" *ngIf="auth.isAdmin() && !gavekort.refundert" (click)="sendGavekort(gavekort)">Send på nytt</button>
      </div>
    </div>

    <h3>Antall billetter: {{ ordre.billetter.length }}</h3>

    <mdc-list interactive="false" class="ordre__billettliste content-list">
      <mdc-list-item *ngFor="let billett of ordre.billetter">
        <p class="billettliste__uuid">Billett {{billett.uuid}}</p>
        <p *ngIf="billett.plassref && !billett.slettet">{{billett.plasstxt}}</p>
        <p *ngIf="ordre.avsluttet && !billett.slettet && !billett.refundert">Gyldig</p> 
        <p *ngIf="billett.refundert">Refundert</p>
        <p *ngIf="!ordre.avsluttet || billett.slettet">Ikke gyldig</p>
      </mdc-list-item>
    </mdc-list>

    <button *ngIf="ordreHarGyldigeBilletter()" mdc-button (click)="printBilletter()">Skriv ut billetter</button>

  </section>

  <section *ngIf="ordre.avsluttet && !ordre.slettet && auth.isAdmin()" class="ordre__knapper">
    <!-- <button mdc-button primary (click)="print(ordre)">Skriv ut billetter</button> -->
    <!-- <button mdc-button primary (click)="mail(ordre)">Send bekreftelse på epost</button> -->
    <button mdc-button unelevated [disabled]="pending" *ngIf="ordreErRefunderbarTilKort(ordre)" (click)="refundOrdre(ordre)">Refunder ordre</button>
    <!-- <button mdc-button class="button-no-bg" (click)="delete(ordre)">Slett ordre</button> -->
  </section>
</div>