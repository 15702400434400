
import { ContentService } from '../../content.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MdcDialog } from '@angular-mdc/web';
import { EMPTY, forkJoin } from 'rxjs';
import { AuthService} from '../../auth.service';
import { debounceTime, switchMap } from "rxjs/operators";
import { VelgSeteComponent } from 'src/app/salg/dialogs/velg-sete/velg-sete.component';

@Component({
  selector: 'app-kjoreplan',
  templateUrl: './kjoreplan.component.html',
  styleUrls: ['./kjoreplan.component.scss']
})
export class KjoreplanComponent implements OnInit {
    filterForm;
    public spillestedInput = new FormControl();
    public filterInfotekst;
    maskinister;
    ringer;
    spillesteder;
    filtrerteSpillesteder = [];
    kjoreplan = [];
    pending = true;

  constructor(
    public dialog: MdcDialog,
    private contentService: ContentService,
    private auth: AuthService
  ) { }

  ngOnInit() {
      forkJoin(
        this.contentService.fetchAktiveMaskinister(),
        this.contentService.fetchContent('/ring?sort=nr')
      ).subscribe((res: any) => {
        this.maskinister = res[0]._items;
        this.ringer = res[1]._items.filter(ring => ring.aktiv === true);

        const maskinistRing = this.ringer.filter(ring => {
          if (this.auth.getUserInfo().maskinist) {
            return ring.maskinist === this.auth.getUserInfo().maskinist;
          } else {
            return false;
          }
        });

        const maskinistId = this.auth.getUserInfo().maskinist || '';

        this.filterForm = new FormGroup({
          ring: new FormControl(maskinistRing.length ? maskinistRing[0].id : ''),
          maskinist: new FormControl(maskinistId),
          spillested: new FormControl()
        });

        this.filterForm.valueChanges.subscribe(
          res => {
            this.oppdaterKjoreplan()
          }
        )

        this.spillestedInput
          .valueChanges
          .pipe(
            debounceTime(300),
            switchMap((value: any) => {
                if (value) {
                  return this.contentService.fetchContentWithQueryParams('/spillested', {q: value, aktiv: true});
                } else {
                  this.filterForm.controls.spillested.setValue(null);
                  this.filtrerteSpillesteder = [];
                  return EMPTY;
                }
              }
            )
          )
          .subscribe(spillesteder => {
            this.filtrerteSpillesteder = spillesteder;
          });
          this.oppdaterKjoreplan();
      });
  }

  oppdaterKjoreplan() {
    this.kjoreplan = [];
    const formValues = this.filterForm.value;

    if (! (formValues.ring || formValues.maskinist || formValues.spillested)) {
      this.contentService.fetchContent('/kjoreplan')
      .subscribe(
        res => {
          this.filterInfotekst = 'Viser kjøreplan for alle ringer';
          this.kjoreplan = res;
          this.pending = false;}
          );
    } else {
      this.getFilteredKjoreplan(formValues);
    }
  }
      
  private getFilteredKjoreplan(formValues) {
    const sources = [];
    if (formValues.ring) {
      sources.push(this.contentService.fetchContent('/ring/' + formValues.ring + '/kjoreplan/'));
    }
    if (formValues.maskinist) {
      sources.push(this.contentService.fetchContent('/maskinist/' + formValues.maskinist + '/kjoreplan/'));
    }
    if (formValues.spillested) {
      sources.push(this.contentService.fetchContent('/spillested/' + formValues.spillested + '/kjoreplan/'));
    }

    forkJoin(...sources)
      .subscribe(res => {
        res.forEach(subRes => {
          this.kjoreplan = this.kjoreplan.concat(subRes);

          this.filterInfotekst = this.formatFilterInfotekst(formValues);
          const uniques = [];
          const map = new Map();
          for (const item of this.kjoreplan) {
            if (!map.has(item.id)) {
              map.set(item.id, true);
              uniques.push(item);
            }
          }
          this.kjoreplan = uniques.sort((a, b) => a.dato > b.dato ? 1 : -1);
          this.pending = false;
        });
      });
  }

  private formatFilterInfotekst(formValues) {
    let txt = 'Viser kjøreplan for: ';
    const filterItems = [];
    if (formValues.ring) {
      filterItems.push('Ring ' + this.ringer.find(r => r.id === parseInt(formValues.ring)).nr);
    }
    if (formValues.maskinist) {
      filterItems.push(this.maskinister.find(m => m.id === parseInt(formValues.maskinist)).navn);
    }
    if (formValues.spillested) {
      filterItems.push(this.spillestedInput.value);
    }
    return txt + filterItems.join(' og ');
  }

  velgSpillested(spillested) {
    this.filtrerteSpillesteder = [];
    this.filterForm.controls.spillested.setValue(spillested.id)
    this.oppdaterKjoreplan();
    this.spillestedInput.setValue(spillested.navn, {emitEvent: false});
  }

  getNavnFromMaskinistId(id) {
    return this.maskinister.find(maskinist => maskinist.id === id).navn;
  }

  aapneSalkart(forestilling) {
    this.contentService.fetchContent('/salg/forestilling/' + forestilling.id + '/info')
      .subscribe(forestillinginfo => {
        this.dialog.open(VelgSeteComponent, {
          data: { info: forestillinginfo, modus: 'kjoreplan' }
        });
      });
  }

}
