import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import QrScanner from 'qr-scanner';
import { ContentService } from 'src/app/content.service';
import { MdcDialogRef, MdcDialogAction } from '@angular-mdc/web';
import { fmtPaymentType } from '../../print-stuff'
QrScanner.WORKER_PATH = '/assets/qr-scanner-worker.min.js';

@Component({
  selector: 'app-refunder-billetter',
  templateUrl: './refunder-billetter.component.html',
  styleUrls: ['./refunder-billetter.component.scss']
})
export class RefunderBilletterComponent implements OnInit, AfterViewInit, OnDestroy {
  public billettnummer = new FormControl();
  public billettnummerForm = new FormGroup({
    billettnummer: this.billettnummer
  });
  public billettSearchError: string;
  public ordre: Ordre;
  public forestilling;
  public spillested;
  private qrScanner;
  public tidligereRefundert = 0;
  public antallUrefunderte = 0;
  public refunderingsTypeControl = new FormControl('cash');
  public refundTypes: string[];
  public fmtPaymentType = fmtPaymentType;
  public ordre_kontantrefundering_mulig: boolean;

  refundTypeForm = new FormGroup({
    refund_type: new FormControl('cash'),
  });


  @ViewChild('initialfocuselem', {static: false}) private initialfocuselem;
  @ViewChild('scanVideoElm', { static: false }) private scanVideoElm;
  constructor(
    private contentService: ContentService,
    private dialogRef: MdcDialogRef<RefunderBilletterComponent>
  ) {}

  ngOnInit() {
  }

  ngOnDestroy() {
    this.qrScanner.stop();
  }

  ngAfterViewInit() {
    this.qrScanner = new QrScanner(this.scanVideoElm.nativeElement, this.getBillett.bind(this));
    this.qrScanner.start();
  }

  initfocus() {
    if (this.initialfocuselem && this.initialfocuselem.nativeElement) {
      const input = this.initialfocuselem.nativeElement.querySelector('input[type="radio"]');
      setTimeout(() => input.focus(), 200);
    }
  }

  matchUUIDs(full: string, partial: string): boolean {
    return ( full.indexOf(partial) === 0 );
  }

  refundTicket(id) {
    const refund_type = this.refundTypeForm.value.refund_type;
    this.dialogRef.close({
      refund_what: 'ticket',
      refund_type: refund_type,
      id: id,
      fid: this.forestilling.id
    });
  }

  refundOrdre(id) {
    const refund_type = this.refundTypeForm.value.refund_type;// cash / fribillett / kort
    this.dialogRef.close({
      refund_what: 'ordre',
      refund_type: refund_type,
      id: id,
      fid: this.forestilling.id
    });
  }

  getRefundTypes(ordre) {
    if (ordre.ordretype === 'nett') {
      if (this.ordreErRefunderbarTilKort(ordre)) {
        return ['fribillett', 'kort'];
      } else {
        return ['fribillett'];
      }
    } else {
        return ['cash', 'fribillett'];
    }
  }

  ordreErRefunderbarTilKort(ordre) {
    // alt må være betalt på kort i en transaksjon for at vi skal
    return this.kunEnKortTransaksjon(ordre) && this.betaltMedKort(ordre) && this.ingenRefunderingerGjort(ordre)
  }

  kunEnKortTransaksjon(ordre) {
    return ordre.ant_kortreservasjoner === 1;
  }

  betaltMedKort(ordre) {
    return ordre.ordretype === 'nett' && (ordre.sum_captured_kort === ordre.sum);
  }

  ingenRefunderingerGjort(ordre) {
    return ordre.sum_refunded_kort === 0;
  }

  getBillett(uuid) {
    if (! uuid) {return false; }
    this.billettnummer.setValue(uuid);
    this.contentService.fetchContent('/salg/ticketscan/' + uuid)
      .subscribe(
        res => {
          this.ordre = res.ordre;
          this.ordre_kontantrefundering_mulig = res.ordre_kontantrefundering_mulig;
          this.forestilling = res.forestilling;
          this.spillested = res.spillested;
          this.antallUrefunderte = res.ordre.billetter.filter(b => !b.refundert).length;
          this.tidligereRefundert = this.ordre.billetter.reduce((acc, b) => {
            if (b.refundert) {
              return acc + b.belop;
            } else {
              return acc;
            }
          }, 0);
          this.refundTypes = this.getRefundTypes(res.ordre);
          const defaultRefundType = 'fribillett';
          this.refundTypeForm.get('refund_type').setValue(defaultRefundType);

        }
      )
    this.qrScanner.stop();
  }

  searchBillett(str: string) {
    this.billettSearchError = null;
    this.contentService.fetchContent('/salg/ticketsearch/' + str)
      .subscribe(
        res => {
          if (res.result) {
            this.ordre = res.result.ordre;
            this.ordre_kontantrefundering_mulig = res.result.ordre_kontantrefundering_mulig;
            this.forestilling = res.result.forestilling;
            this.spillested = res.result.spillested;
            this.antallUrefunderte = res.result.ordre.billetter.filter(b => !b.refundert).length;
            this.tidligereRefundert = this.ordre.billetter.reduce((acc, b) => {
              if (b.refundert) {
                return acc + b.belop;
              } else {
                return acc;
              }
            }, 0);
            this.initfocus();
            this.refundTypes = this.getRefundTypes(res.result.ordre);
            const defaultRefundType = 'fribillett';
            this.refundTypeForm.get('refund_type').setValue(defaultRefundType);
          } else {
            this.billettSearchError = res.error;
          }
        }
      )
      this.qrScanner.stop();
  }
}
