<app-spinner *ngIf="pending"></app-spinner>

<header class="content-header">
    <h2 mdcHeadline2>Kjøreplan</h2>
    <form class="kjoreplan__form" *ngIf="ringer" [formGroup]="filterForm">
        <mdc-select
          outlined
          placeholder="Ring"
          formControlName="ring"
        >
          <mdc-menu>
            <mdc-list>
              <mdc-list-item value=""></mdc-list-item>
              <mdc-list-item *ngFor="let ring of ringer" [value]="ring.id">{{ring.nr }} {{ ring.navn }}</mdc-list-item>
            </mdc-list>
          </mdc-menu>
        </mdc-select>

        <mdc-select
          outlined
          placeholder="Maskinist"
          formControlName="maskinist"
        >
          <mdc-menu>
            <mdc-list>
              <mdc-list-item value=""></mdc-list-item>
              <mdc-list-item *ngFor="let maskinist of maskinister" [value]="maskinist.id">{{ maskinist.navn }}</mdc-list-item>
            </mdc-list>
          </mdc-menu>
        </mdc-select>
        
        <mdc-text-field outlined autocomplete="off" label="Spillested" #spilleInput [formControl]='spillestedInput'>
        </mdc-text-field>

        <mdc-list class="form__spillested-liste">
          <mdc-list-item (click)="velgSpillested(spillested)" *ngFor="let spillested of filtrerteSpillesteder">
              {{ spillested.navn }}
          </mdc-list-item>

        </mdc-list>

    </form>
</header>

<div class="kjoreplan__filter">{{ filterInfotekst }}</div>

<section class="kjoreplan">
    <div class="kjoreplan-item" *ngFor="let item of kjoreplan">
      <div class="kjoreplan-item__header">
        <div>
          <h3 mdcHeadline5 *ngIf="item.er_kjoredag">Kjøredag</h3>
          <h3 mdcHeadline5 *ngIf="item.spillested">{{item.spillested.stedsnavn}}<span class="header__tag">{{item.spillested.nr}}</span></h3>
        </div>
        <div class="header__right">
          <p mdcHeadline6>{{item.dato | date:'d.M' }}</p>
          <app-kjoreplan-valg [spilledag]="item" (valgt)="oppdaterKjoreplan()"></app-kjoreplan-valg>
        </div>
      </div>

      <div class="kjoreplan-item__content" *ngIf="item.spillested">
        <div>
          <div class="kjoreplan-item__address">
            <p>{{item.spillested.navn}}</p>
            <p *ngIf="item.spillested.besoksadr">{{item.spillested.besoksadr}}</p>
            <p>{{item.spillested.postnummer}} {{item.spillested.stedsnavn}}</p>
          </div>
          <div class="kjoreplan-item__important">
            <p *ngIf="item.alt_maskinist">OBS: Denne spilledagen kjøres av {{ getNavnFromMaskinistId(item.alt_maskinist_id) }}</p>
            <p *ngIf="item.er_skolekino">OBS: Skolekino</p>
            <p *ngIf="item.alt_sted">Nytt sted: {{item.alt_sted}}</p>
            <p *ngIf="item.merknad" class="merknad">{{ item.merknad }}</p>
          </div>
          <div class="kjoreplan-item__contact">
            <p class="contact__name" *ngIf="item.spillested.kontaktperson">
              {{item.spillested.kontaktperson}}
            </p>
            <p class="contact__tlf" *ngIf="item.spillested.telefon_kontakt">
              <a href="tel:{{item.spillested.telefon_kontakt}}">{{item.spillested.telefon_kontakt}}</a>
            </p>
            <p class="contact__email" *ngIf="item.spillested.epost_kontakt">
              <a href="mailto:{{item.spillested.epost_kontakt}}">{{item.spillested.epost_kontakt}}</a>
            </p>
          </div>
        </div>
        <div>
            <div class="kjoreplan-item__shows" *ngIf="! item.er_avlyst">
                <div class="show" *ngFor="let forestilling of item.forestillinger" [ngClass]="{'er_avlyst': forestilling.er_avlyst}">
                  <p class="show__time"><b>{{forestilling.tidspunkt}}</b></p>
                  <div>
                    <p>
                      <b>{{forestilling.film.norsktittel}} {{forestilling.film.version_subtitle}}</b>
                      <span class="show--canceled" *ngIf="forestilling.er_avlyst">Avlyst</span>
                      <span *ngIf="forestilling.er_avlyst && forestilling.avlyst_grunn"> – {{ forestilling.avlyst_grunn }}</span>
                    </p>
                    <p><span class="content__tag text__medium-emphasis">tid:</span> {{ forestilling.film.spilletid | minutesToHours }}</p>
                    <p><span class="content__tag text__medium-emphasis">alder:</span> {{ forestilling.film.ageRating }}</p>
                    <p><span class="content__tag text__medium-emphasis">dist:</span> {{ forestilling.film.distributor }}</p>
                    <p><span class="content__tag text__medium-emphasis">pris:</span> {{ forestilling.pris }}</p>
                    <p><span class="content__tag text__medium-emphasis">solgte billetter:</span> {{ forestilling.ticket_summary.n_solgt }}</p>
                    <button mdc-button *ngIf="forestilling.sal_id" class="button-no-bg" (click)="aapneSalkart(forestilling)">Salkart</button>
                  </div>
                </div>
                <p *ngIf="!item.forestillinger.length"><i>Ingen filmer er lagt til denne spilledagen ennå.</i></p>
            </div>
            <div *ngIf="item.er_avlyst">
                <p class="kjoreplan-item--canceled">Avlyst</p>
                <p>Begrunnelse: {{ item.avlyst_grunn }}</p>
            </div>
        </div>
      </div>
    </div>
    <div *ngIf="!pending && !kjoreplan.length">
      <p>Finner ingen kjøreplan med valgt filter</p>
    </div>
</section>
